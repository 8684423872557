body {
  margin: 0;
  font-family: "Gotu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4 {
  font-family: "Poiret One", cursive;
}

h5,
h6 {
  font-family: "Poiret One", cursive;
  margin-top: 5px;
  margin-bottom: 5px;
}

h5 {
  font-size: 1.2em;
}

h6 {
  font-size: 1em;
}

#root {
  overflow: hidden;
  background-color: #f9f9f9;
}

@font-face {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 500;
  /* src: local('RoxboroughCF'), url('./assets/fonts/RoxboroughCF.ttf') format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  /*src: local('RoxboroughCFThin'), url('./assets/fonts/RoxboroughCFThin.ttf') format('truetype') /* Safari, Android, iOS */
}
